import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const myTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#DA291C',
      },
      secondary: {
        main: '#565a6e',
      },
      error: {
        main: '#DA291C',
      },
      warning: {
        main: '#FFF2CC',
      },
      info: {
        main: '#0B233F',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#FFFFFF',
        paper: '#FFFFFF',
      },
      banner: {
        info: '#0B233F',
        error: '#DA291C',
        text: '#FFFFFF',
        link: '#565a6e',
      },
      errorBackground: '#DA291C',
      warningBackground: '#FFF2CC',
      infoBackground: '#0B233F',
      navigation: {
        background: '#0B233F',
        indicator: '#FFF2CC',
        color: '#FFFFFF',
        selectedColor: '#ffffff',
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#DA291C', '#0B233F'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#DA291C', '#0B233F'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#DA291C', '#0B233F'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#DA291C', '#0B233F'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#DA291C', '#0B233F'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#DA291C', '#0B233F'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#DA291C', '#0B233F'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#DA291C', '#0B233F'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#DA291C', '#0B233F'], shape: shapes.wave }),
  },
});
