import {
  HomePageStarredEntities,
  HomePageTopVisited,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { HomePageSearchBar } from '@backstage/plugin-search';
import cloudbank from '../Root/logo-png.png';

const useStyles = makeStyles(myTheme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: myTheme.palette.background.paper,
    borderRadius: '25px',
    padding: myTheme.spacing(1, 2),
    border: '2px solid transparent',
    boxShadow: '0px 4px 6px rgba(0.6, 0.6, 0.6, 0.6)',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const CardStyle = makeStyles(myTheme => ({
  CardContainer: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: myTheme.palette.background.default,
    padding: myTheme.spacing(2),
    borderRadius: '25px',
    border: '2px solid transparent',
    boxShadow: '0px 4px 6px rgba(0.1, 0.1, 0.1, 0.1)',
  },
}));

const useStylesLogo = makeStyles({
  img: {
    width: 'auto',
    height: 250,
  },
});

const LogoIcon = () => {
  const classesLogo = useStylesLogo();

  return <img className={classesLogo.img} src={cloudbank} alt="Logo" />;
};

export const HomePage = () => {
  const classes = useStyles();
  const card = CardStyle();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid item xs={12} container justifyContent="center">
              <LogoIcon />
            </Grid>

            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search "
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <div className={card.CardContainer}>
                  <HomePageStarredEntities />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className={card.CardContainer}>
                  <HomePageTopVisited />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
